import React from "react"
import { navigate } from "gatsby-link"
import { Helmet } from "react-helmet"
import queryString from "query-string"
import LayoutDark from "./shared/layout/layout-dark"
import "../../static/assets/scss/pages/page.scss"
import SignUpFormComponent from "./shared/components/sign-up-form.component"
const routes = require("../types/routes")

export default class GetStarted extends React.Component {
  constructor(props) {
    super(props)
    this.routes = routes
    this.form = React.createRef()
    this.firstName = React.createRef()
    this.lastName = React.createRef()
    this.email = React.createRef()
    this.phone = React.createRef()
    this.company = React.createRef()
    this.button = React.createRef()
  }

  componentDidMount() {
    const values = queryString.parse(this.props.location.search)

    if (values.email) {
      this.email.current.value = values.email
    }
  }

  onButtonStateLoading() {
    this.button.current.classList.add("spinner", "loading")
  }

  onButtonStateDefault() {
    this.button.current.classList.remove("spinner", "disabled", "loading")
  }

  onValidate() {
    const fields = [
      this.firstName.current,
      this.lastName.current,
      this.email.current,
      this.phone.current,
      this.company.current,
    ]
    const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    const namePattern = /^[a-zA-Z\s]*$/
    const phonePattern = /^(?=.*[0-9])[- +()0-9]+$/

    const emailRegex = RegExp(emailPattern)
    const textRegex = RegExp(namePattern)
    const phoneRegex = RegExp(phonePattern)

    let isValid = true
    for (const field of fields) {
      if (field.id === "email") {
        const valid = emailRegex.test(field.value) && field.value.length >= 3
        valid ? this.onMarkAsValid(field) : this.onMarkAsInvalid(field)
        if (valid === false) {
          isValid = false
        }
        const invalidDomains = [
          "gmail.com",
          "hotmail.com",
          "live.com",
          "yahoo.com",
          "icloud.com",
        ]
        const isDomainValid = invalidDomains.every(
          substring => field.value.indexOf(substring) === -1
        )
        if (isDomainValid === false) {
          isValid = false
          this.onMarkAsInvalid(field)
        }
      } else if (field.id === "phone") {
        const valid = field.value.length >= 7 && phoneRegex.test(field.value)
        valid ? this.onMarkAsValid(field) : this.onMarkAsInvalid(field)
        if (valid === false) {
          isValid = false
        }
      } else {
        const valid = textRegex.test(field.value) && field.value.length >= 3
        valid ? this.onMarkAsValid(field) : this.onMarkAsInvalid(field)
        if (valid === false) {
          isValid = false
        }
      }
    }
    return isValid
  }

  onMarkAsInvalid(field) {
    field.parentNode.classList.add("error")
    this.button.current.classList.add("disabled")
  }

  onMarkAsValid(field) {
    field.parentNode.classList.remove("error")
    this.button.current.classList.remove("disabled")
  }

  onEnterKeypress(e) {
    this.onMarkAsValid(e.target)
    if (e.key === "Enter") {
      e.preventDefault()
      this.onSubmitForm(e)
    }
  }

  onGetCookies(string) {
    return Object.fromEntries(
      document.cookie.split("; ").map(v => v.split("=").map(decodeURIComponent))
    )[string]
  }

  onSubmitForm(e) {
    this.onButtonStateLoading()
    if (this.onValidate()) {
      const formData = new FormData(this.form.current)
      const hutk = this.onGetCookies("hubspotutk")
      const data = {
        fields: [
          { name: "firstname", value: formData.get("firstname") },
          { name: "lastname", value: formData.get("lastname") },
          { name: "email", value: formData.get("email") },
          { name: "phone", value: formData.get("phone") },
          { name: "company", value: formData.get("company") },
        ],
        context: {
          hutk: hutk,
          pageUri: window.location.href,
        },
      }
      this.ajax(
        this.form.current.method,
        this.form.current.action,
        data,
        data => {
          this.onButtonStateDefault()
          navigate(
            `${this.routes.routes.getStartedSuccess}?email=${this.email.current.value}`,
            {}
          )
        },
        (error, data) => {
          const resp = JSON.parse(data)
          const message = resp.errors[0].message
          this.onButtonStateDefault()
          this.onMarkAsInvalid(message)
        }
      )
    } else {
      setTimeout(() => {
        this.onButtonStateDefault()
      }, 500)
    }
  }

  ajax(method, url, data, success, error) {
    var xhr = new XMLHttpRequest()
    xhr.open(method, url)
    xhr.setRequestHeader("Content-Type", "application/json")
    xhr.onreadystatechange = function() {
      if (xhr.readyState !== XMLHttpRequest.DONE) return
      if (xhr.status === 200) {
        success(xhr.response, xhr.responseType)
      } else {
        error(xhr.status, xhr.response, xhr.responseType)
      }
    }
    xhr.send(JSON.stringify(data))
  }

  render() {
    return (
      <LayoutDark>
        <Helmet>
          <title>Contingent: Request a demo</title>
          <meta name="title" content="Contingent: Request a demo" />
          <meta property="og:type" content="website" />
          <link rel="canonical" href={`${process.env.BASE_URL}/get-started/`} />
          <meta
            property="og:url"
            content={`${process.env.BASE_URL}/get-started/`}
          />
          <meta property="og:title" content="Contingent: Request a demo" />
          <meta
            name="description"
            content="Book a free slot using the calendar below, we'll have a short onboarding call before we set up your Contingent account."
          />
          <meta
            property="og:image"
            content={`${process.env.BASE_URL}/assets/images/web/social-image.png`}
          />
          <meta property="twitter:card" content="summary_large_image" />
          <meta
            property="twitter:url"
            content={`${process.env.BASE_URL}/get-started/`}
          />
          <meta property="twitter:title" content="Contingent: Request a demo" />
          <meta
            name="og:description"
            content="Book a free slot using the calendar below, we'll have a short onboarding call before we set up your Contingent account."
          />
          <meta
            name="twitter:description"
            content="Book a free slot using the calendar below, we'll have a short onboarding call before we set up your Contingent account"
          />
          <meta
            property="twitter:image"
            content={`${process.env.BASE_URL}/assets/images/web/social-image.png`}
          />
        </Helmet>
        <div className={"section-container"}>
          <div className={"section-info basic"}>
            <div className={"container contact-us"}>
              <div className={"row"}>
                <div className={"column"}>
                  <div className={"content"}>
                    <p className={"tag"}>Request a demo</p>
                    <h1 className={"title"}>Create your free account</h1>
                    <p className={"description"}>
                      Create your account now and start banishing manual
                      processes in favour of proactive, ongoing risk monitoring.
                      <br /> <br />
                      One of our representatives will reach out to you to help
                      onboard your company on Contingent.
                    </p>
                    <p className={"description bold"}>
                      Trusted by organisations of all shapes and sizes
                    </p>
                    <div className={"logos collapsed"}>
                      <div className={"group"}>
                        <img
                          src={"/assets/images/logos/ukgovblack.svg"}
                          className={"logo"}
                        />
                        <img
                          src={"/assets/images/logos/monzo-black.svg"}
                          className={"logo"}
                        />
                      </div>
                      <div className={"group"}>
                        <img
                          src={"/assets/images/logos/healthcare.svg"}
                          className={"logo"}
                        />
                        <img
                          src={"/assets/images/logos/vyne.svg"}
                          className={"logo"}
                        />
                      </div>
                    </div>
                  </div>
                  <form
                    className={"form dark-form"}
                    ref={this.form}
                    method={"post"}
                    action={
                      "https://api.hsforms.com/submissions/v3/integration/submit/8614381/6de56ecd-f7e3-481e-aac8-4a08cfe2733e"
                    }
                  >
                    <h5 className={"header"}>Request a demo</h5>
                    <div className={"body"}>
                      <div className={"field-container"}>
                        <div className={"label"}>
                          <label className={"label-text"}>First name</label>
                          <p className={"description"}>Required</p>
                        </div>
                        <div className={"field"}>
                          <input
                            ref={this.firstName}
                            onKeyDown={e => this.onEnterKeypress(e)}
                            name="firstname"
                            type={"text"}
                            placeholder={"e.g Tony"}
                          />
                          <div className={"error-text"}>
                            Please provide a valid first name
                          </div>
                        </div>
                      </div>
                      <div className={"field-container"}>
                        <div className={"label"}>
                          <label className={"label-text"}>Last name</label>
                          <p className={"description"}>Required</p>
                        </div>
                        <div className={"field"}>
                          <input
                            ref={this.lastName}
                            onKeyDown={e => this.onEnterKeypress(e)}
                            name="lastname"
                            type={"text"}
                            placeholder={"e.g Stark"}
                          />
                          <div className={"error-text"}>
                            Please provide a valid last name
                          </div>
                        </div>
                      </div>
                      <div className={"field-container"}>
                        <div className={"label"}>
                          <label className={"label-text"}>Email</label>
                          <p className={"description"}>Required</p>
                        </div>
                        <div className={"field"}>
                          <input
                            ref={this.email}
                            onKeyDown={e => this.onEnterKeypress(e)}
                            name="email"
                            type={"text"}
                            id="email"
                            placeholder={"e.g tony@starkenterprise.com"}
                          />
                          <div className={"error-text"}>
                            Please provide a valid work email
                          </div>
                        </div>
                      </div>
                      <div className={"field-container"}>
                        <div className={"label"}>
                          <label className={"label-text"}>Phone</label>
                          <p className={"description"}>Required</p>
                        </div>
                        <div className={"field"}>
                          <input
                            ref={this.phone}
                            onKeyDown={e => this.onEnterKeypress(e)}
                            name="phone"
                            type={"text"}
                            id="phone"
                            placeholder={"e.g Enter a valid phone number"}
                          />
                          <div className={"error-text"}>
                            Please provide a valid phone number
                          </div>
                        </div>
                      </div>
                      <div className={"field-container"}>
                        <div className={"label"}>
                          <label className={"label-text"}>Company</label>
                          <p className={"description"}>Required</p>
                        </div>
                        <div className={"field"}>
                          <input
                            ref={this.company}
                            onKeyDown={e => this.onEnterKeypress(e)}
                            name="company"
                            type={"text"}
                            placeholder={"e.g Stark Enterprises"}
                          />
                          <div className={"error-text"}>
                            Provide valid company name
                          </div>
                          <p className={"description"}>
                            By clicking “Submit”, you agree to our{" "}
                            <span className={"link"}>Terms of Service</span> and{" "}
                            <span className={"link"}>Privacy Statement</span>.
                            We’ll occasionally send you account related emails.
                          </p>
                        </div>
                      </div>
                      <div className={"field-container button"}>
                        <button
                          type={"button"}
                          ref={this.button}
                          onClick={e => this.onSubmitForm(e)}
                        >
                          Submit
                          <div className={"spinner"}></div>
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={"cta-section"}>
          <div className={"container"}>
            <div className={"row"}>
              <div className={"column"}>
                <div className={"content"}>
                  <p className={"tag"}>Try Contingent Now</p>
                  <h2 className={"title"}>
                    Move towards proactive third-party risk management and
                    monitoring today.
                  </h2>
                  <SignUpFormComponent mode={"light"}></SignUpFormComponent>
                </div>
                <div className={"image"}>
                  <object data={"/assets/images/graphics/cta.svg"}></object>
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutDark>
    )
  }
}
